import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';


import { AsesorResponse } from 'src/app/shared/interfaces/asesor-response';
import { Promocion } from '../interfaces/promocion';
import { Sucursal } from '../interfaces/sucursal';
import { Region } from '../interfaces/region';
import { CalendarioResponse } from '../interfaces/calendario-response';
import { ConfirmacionResponse } from '../interfaces/confirmacion-response';
import { Servicio } from '../interfaces/servicio';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  env = environment;

  agendaEndPoint: string;
  promocionesEndPoint: string;
  calendarioEndPoint: string;
  confirmacionEndPoint: string;
  apiKey: string;
  siteId: string;

  allDataResponse: any[];

  agendaDetailResponse: any[];
  asesorResponse: any[];
  calendarioResponse: any[];
  confirmacionResponse: any[];

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {
    this.agendaEndPoint = this.env.agendaEndPoint;
    this.calendarioEndPoint = this.env.calendarioEndPoint;
    this.promocionesEndPoint = this.env.promocionesEndPoint;
    this.confirmacionEndPoint = this.env.confirmacionEndPoint;
    this.siteId = this.env.SiteID;
    this.apiKey = this.env.apiKey;
  }



  private siteIdBypass(idMarca,idSucursal): any{

    switch (idSucursal) {
      case '3158':
        return { ns: '3458', sid:'PE02'};
      break;
      default:
        return { ns: idSucursal, sid:'PE01'}
    }
  }


  public getAsesores(
    idTipoVehiculo: string,
    idMarca: string,
    idRegion: string,
    idSucursal: string,
    idServicio: string
  ): Observable<AsesorResponse[]> {
    let asesores: Array<AsesorResponse> = [];
    const params = new HttpParams();


    const bypassValues = this.siteIdBypass(idMarca,idSucursal);
    let bypassSiteId = bypassValues.sid;
    let bypassSucursal = bypassValues.ns;


    let endpoint =
      this.agendaEndPoint +
      "?$filter=Pais eq '" +
      bypassSiteId +
      "' and TipoVehiculo eq '" +
      idTipoVehiculo +
      "' and Marca eq '" +
      idMarca +
      "' and Region eq '" +
      idRegion +
      "' and Sucursal eq '" +
      bypassSucursal +
      "' and Servicio eq '" +
      idServicio +
      "'";
    endpoint += '&$format=json';

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          this.asesorResponse = data.d.results;
          this.asesorResponse.forEach(function (detail) {
              asesores.push(detail);
          });
          return asesores;
        })
      );
  }

  public getServicios(
    idTipoVehiculo: string,
    idMarca: string,
    idRegion: string,
    idSucursal: string
  ): Observable<Servicio[]> {
    let servicios: Array<Servicio> = [];
    const params = new HttpParams();
    let endpoint =
      this.agendaEndPoint +
      "?$filter=Pais eq '" +
      this.siteId +
      "' and TipoVehiculo eq '" +
      idTipoVehiculo +
      "' and Marca eq '" +
      idMarca +
      "' and Region eq '" +
      idRegion +
      "' and Sucursal eq '" +
      idSucursal +
      "'";
    endpoint += '&$format=json';
    this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .subscribe((data: any) => {
        this.agendaDetailResponse = data.d.results;
        this.agendaDetailResponse.forEach(function (detail:Servicio) {
          let servicio: Servicio;

          if (detail.Sucursal === idSucursal) {
            if (
              servicios
                .map(function (e) {
                  return e.Servicio;
                })
                .indexOf(detail.Servicio) === -1
            ) {
              servicios.push(detail);
            }
          }
        });
      });
    return of(servicios);
  }

  public getPromociones(
    vehiculo: any,
    fecha: string,
    sucursal: string,
    km: string
  ): Observable<Promocion[]> {
    let promociones: Array<Promocion> = [];
    let endpoint =
      this.promocionesEndPoint +
      "?$filter=Canal eq '0001' and Centro eq '" +
      sucursal +
      "' and MarcaId eq '" +
      vehiculo.marca +
      "' and TipvehId eq '" +
      vehiculo.tipo +
      "' and Mcodesd eq '" +
      vehiculo.modelo +
      (km === '' ? '' : "' and Km eq '" + km) +
      "' and Fecha eq '" +
      fecha +
      "' and Matricula eq '" +
      (vehiculo.patente.substring(0, 3) +
        '-' +
        vehiculo.patente.substring(3, vehiculo.patente.length)) +
      "' and Siteid eq '" +
      this.siteId +
      "'";
    endpoint += '&$format=json';
    this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .subscribe((data: any) => {
        const auxPromociones = data.d.results;
        auxPromociones.forEach((item:Promocion) => {
          promociones.push({
            ...item,
            // Revisa y asigna imagen defecto en caso de no tener
            Imagen: this.domSanitizer.bypassSecurityTrustUrl(
              item.Imagen === ''
                ? './assets/images/promocion.jpg'
                : 'data: image/jpeg;base64,' + item.Imagen
            ),
            Icono: this.domSanitizer.bypassSecurityTrustUrl(
              item.Icono === ''
                ? './assets/images/promocion.jpg'
                : 'data: image/jpeg;base64,' + item.Icono
            ),
            Precio: item.Precio.split('.')[0],
          });
        });
        // promociones.push() = ;
      });
    return of(promociones);
  }

  public getSucursales(
    idTipoVehiculo: string,
    idMarca: string,
    idRegion: string
  ): Observable<Sucursal[]> {
    let sucursales: Array<Sucursal> = [];
    const params = new HttpParams();
    let endpoint =
      this.agendaEndPoint +
      "?$filter=Pais eq '" +
      this.siteId +
      "' and TipoVehiculo eq '" +
      idTipoVehiculo +
      "' and Marca eq '" +
      idMarca +
      "' and Region eq '" +
      idRegion +
      "'";
    endpoint += '&$format=json';

    this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .subscribe((data: any) => {
        this.agendaDetailResponse = data.d.results;
        this.agendaDetailResponse.forEach(function (detail:Sucursal) {

          if (detail.Region === idRegion) {
            if (
              sucursales
                .map(function (e) {
                  return e.Sucursal;
                })
                .indexOf(detail.Sucursal) === -1
            ) {
              sucursales.push(detail);
            }
          }
        });
      });
    return of(sucursales);
  }

  public getRegiones(
    idTipoVehiculo: string,
    idMarca: string
  ): Observable<Region[]> {
    let regiones: Array<Region> = [];
    const params = new HttpParams();
    let endpoint =
      this.agendaEndPoint +
      "?$filter=Pais eq '" +
      this.siteId +
      "' and TipoVehiculo eq '" +
      idTipoVehiculo +
      "' and Marca eq '" +
      idMarca +
      "'";
    endpoint += '&$format=json';
    this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .subscribe((data: any) => {
        this.agendaDetailResponse = data.d.results.sort(function (
          a: any,
          b: any
        ) {
          return a.Region - b.Region;
        });
        this.agendaDetailResponse.forEach(function (detail:Region) {


          if (
            regiones
              .map(function (e) {
                return e.Region;
              })
              .indexOf(detail.Region) === -1
          ) {
            regiones.push(detail);
          }
        });
      });
    return of(regiones);
  }

  public getAll(): Observable<any[]> {
    
    let relacion: Array<any> = [];

    
    let endpoint =
      this.env.agendaEndPointV2 +
      "?$filter=Pais eq '" +
      this.siteId + "'";

    endpoint += '&$format=json';
    
    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          this.allDataResponse = data.d.results;
          for (let dato of this.allDataResponse) {
            relacion.push(dato);
          }
          return relacion;
        })
      );
  }

  public getContact(placa:string): Observable<any> {
    let endpoint =
    environment.datosContacto + `(Placa='${placa}',Stcd1=' ',Stcdt=' ')?$format=json`;
  
    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          return data.d;
        })
      );
  }

  public getCalendarios(
    idSucursal: string,
    startDate: string,
    dias: any,
    idAsesor: string,
    idServicio: string,
    idMarca: string,
    idTipoVehiculo: string
  ): Observable<CalendarioResponse[]> {
    let fechas: Array<CalendarioResponse> = [];
    const params = new HttpParams();

    let endpoint =
      this.calendarioEndPoint +
      "?$filter=Plant eq '" +
      idSucursal +
      "' and Startdate eq '" +
      startDate +
      "' and Dias eq " +
      dias +
      " and Pernr eq '" +
      idAsesor +
      "' and TipoServ eq '" +
      idServicio +
      "' and Marca eq '" +
      idMarca +
      "' and TipoVeh eq '" +
      idTipoVehiculo +
      "'";
    endpoint += '&$format=json';

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          this.calendarioResponse = data.d.results;
          for (let dato of this.calendarioResponse) {
            fechas.push(dato);
          }
          return fechas;
        })
      );
  }

  public getConfirmAgenda(
    reservasMasterCode: string,
    rutUsuario: string,
    rutEmpresa: string,
    patente: string,
    reservasFecha: string,
    reservasHora: string,
    sucursalesCodigosap: string,
    asesoresCodigosap: string,
    reservasObservacion: string,
    reservasTrabAdicional: string,
    reservasAceptaTerminos: string,
    reservasAceptaPromociones: string,
    reservasAvisoId: string,
    citasHorometro: string,
    citasKilometraje: string,
    detalleFalla: string,
    aseguradorasCodigoSap: string,
    numSiniestro: string,
    contratoId: string,
    tiposServiciosCodigoSap: string,
    tiposVehiculoCodigoSap: string,
    marcasVehiculoCodigoSap: string,
    modelosVehiculoCodigoSap: string,
    contactoRut: string,
    contactoNombres: string,
    contactoApellidos: string,
    contactoMail: string,
    contactoFono: string
  ): Observable<ConfirmacionResponse[]> {
    let confirmacionData: Array<ConfirmacionResponse> = [];
    const params = new HttpParams();

    const bypassValues = this.siteIdBypass(marcasVehiculoCodigoSap,sucursalesCodigosap);
    let bypassSiteId = bypassValues.sid;
    let bypassSucursal = bypassValues.ns;

    let endpoint =
      this.confirmacionEndPoint +
      "?$filter=ReservasMode eq '" +
      bypassSiteId +
      "' and ReservasMasterCode eq '" +
      reservasMasterCode +
      "' and RutUsuario eq '" +
      rutUsuario +
      "' and RutEmpresa eq '" +
      rutEmpresa +
      "' and Patente eq '" +
      patente +
      "' and ReservasFecha eq '" +
      reservasFecha +
      "' and ReservasHora eq '" +
      reservasHora +
      "' and SucursalesCodigosap eq '" +
      bypassSucursal +
      "' and AsesoresCodigosap eq '" +
      asesoresCodigosap +
      "' and ReservasObservacion eq '" +
      reservasObservacion +
      "' and ReservasTrabAdicional eq '" +
      reservasTrabAdicional +
      "' and ReservasAceptaTerminos eq '" +
      reservasAceptaTerminos +
      "' and ReservasAceptaPromociones eq '" +
      reservasAceptaPromociones +
      "' and ReservasAvisoId eq '" +
      reservasAvisoId +
      "' and CitasHorometro eq '" +
      citasHorometro +
      "' and CitasKilometraje eq '" +
      citasKilometraje +
      "' and DetalleFalla eq '" +
      detalleFalla +
      "' and AseguradorasCodigoSap eq '" +
      aseguradorasCodigoSap +
      "' and NumSiniestro eq '" +
      numSiniestro +
      "' and ContratoId eq '" +
      contratoId +
      "' and TiposServiciosCodigoSap eq '" +
      tiposServiciosCodigoSap +
      "' and TiposVehiculoCodigoSap eq '" +
      tiposVehiculoCodigoSap +
      "' and MarcasVehiculoCodigoSap eq '" +
      marcasVehiculoCodigoSap +
      "' and ModelosVehiculoCodigoSap eq '" +
      modelosVehiculoCodigoSap +
      "' and ContactoRut eq '" +
      contactoRut +
      "' and ContactoNombres eq '" +
      contactoNombres +
      "' and ContactoApellidos eq '" +
      contactoApellidos +
      "' and ContactoMail eq '" +
      contactoMail +
      "' and ContactoFono eq '" +
      contactoFono +
      "'";
    endpoint += '&$format=json';

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          this.confirmacionResponse = data.d.results;
          for (let dato of this.confirmacionResponse) {
            confirmacionData.push(dato);
          }
          return confirmacionData;
        })
      );
  }


  public getReAgenda(
    reagendaCodigo: string,
    reagendaServicio: string,
    reagendaSucursal: string,
    reagendaAsesor: string,
    reagendaFecha: string,
    reagendaHorario: string,
    reagendaMotivo: string,
  ): Observable<any> {
 
    let endpoint =
      environment.reagendaEndPoint +
      `(Asesor='${reagendaAsesor}',Fecha=datetime'${reagendaFecha}T00:00:00',Hora=time'${reagendaHorario}',Id=' ',Idmotivo='${reagendaMotivo}',Mastercode='${reagendaCodigo}',SiteId='PE01',Sucursal='${reagendaSucursal}',Tipodeservicio='${reagendaServicio}')`;
    

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key':  this.apiKey } })
      .pipe(
        map((data: any) => {
          this.confirmacionResponse = data.d;
          return data.d;
        })
      );
  }
}
